<template>
  <div>
    <SubNav main-route="meta" section="indicator" title="Create Indicator(s)" />
    <div class="container-fluid pb-1"></div>
  </div>
</template>

<script>
import SubNav from "../../../components/NavigationSubNav.vue";

export default {
  name: "Blank",
  components: {
    SubNav,
  },
};
</script>

<style lang="scss" scoped></style>
